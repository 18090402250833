import { Injectable, signal } from '@angular/core';
import type { ToastPosition } from '@vermeer-corp/it-ng-components/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastStateService {
  readonly showCloseButton = signal<boolean>(true);
  readonly expandByDefault= signal<boolean>(false);
  readonly position = signal<ToastPosition>('top-center');
}
