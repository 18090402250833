import { Injectable, signal, Type } from '@angular/core';
import { isArrayWithLength, isNumber } from '@vermeer-corp/it-ng-components/core';
import type { ExternalToast, HeightT, VmrToast, ToastTypes } from './types';

type CreateToastData = ExternalToast & {
  message?: string | Type<unknown>;
  type?: ToastTypes;
};

let _nextUniqueId = 0;

/**
 * Root state service for toasts with methods to create, remove, mutate.
 * See https://ui.vermeer.com/components/toast/examples.
 */
@Injectable({
  providedIn: 'root'
})
export class VmrToastService {
  private readonly _toasts = signal<VmrToast[]>([]);
  private readonly _heights = signal<HeightT[]>([]);

  private readonly _heightsCompareFn = (a: HeightT, b: HeightT): number => {
    const idxA = this._toasts().findIndex(t => t.id === a.toastId);
    const idxB = this._toasts().findIndex(t => t.id === b.toastId);
    return idxA - idxB;
  };

  /** Collection of all toasts. */
  toasts = this._toasts.asReadonly();

  /** Collection of all toast heights. */
  heights = this._heights.asReadonly();

  /** Add a new toast to `_toasts`. */
  addToast(data: VmrToast): void {
    this._toasts.update(prev => [data, ...prev]);
  }

  /** Either add a new toast to `_toasts` or update an existing toast. */
  create(createData: CreateToastData): string | number {
    const {
      message,
      id: dataId,
      type = 'default',
      dismissible = true,
      ...rest
    } = createData;

    const id = isNumber(dataId) || (dataId && dataId?.length > 0) ? dataId : ++_nextUniqueId;
    const alreadyExists = this._toasts().some(t => t.id === id);

    if (alreadyExists) {
      this._toasts.update(prev =>
        prev.map(toast => {
          if (toast.id === id) {
            return {
              ...toast,
              ...createData,
              id,
              title: message,
              dismissible,
              type,
              updated: true,
            };
          } else {
            return {
              ...toast,
              updated: false
            };
          }
        })
      );
    } else {
      this.addToast({
        ...rest,
        id,
        title: message,
        dismissible:
        dismissible,
        type
      });
    }

    return id;
  }

  /** Close all toasts (if any exists). */
  dismissAll(): void {
    if (this._toasts().length > 0) {
      this._toasts.set([]);
    }
  }

  /** Close toast by id. */
  dismiss(id: number | string): number | string {
    const entryExists = this._toasts().some(t => t.id === id);
    if (entryExists) {
      this._toasts.update(prev => prev.filter(toast => toast.id !== id));
    }
    return id;
  }

  /** Create an `info` toast. */
  info(
    message: string | Type<unknown>,
    data?: ExternalToast
  ): number | string {
    return this.create({ ...data, type: 'info', message });
  }

  /** Create an `error` toast. */
  error(
    message: string | Type<unknown>,
    data?: ExternalToast
  ): number | string {
    return this.create({ ...data, type: 'error', message });
  }

    /** Create a `success` toast. */
  success(
    message: string | Type<unknown>,
    data?: ExternalToast
  ): number | string {
    return this.create({ ...data, type: 'success', message });
  }

  /** Create a `warning` toast. */
  warning(
    message: string | Type<unknown>,
    data?: ExternalToast
  ): number | string {
    return this.create({ ...data, type: 'warning', message });
  }

  /** Create a `loading` toast. */
  loading(
    message: string | Type<unknown>,
    data?: ExternalToast
  ): number | string {
    return this.create({ ...data, type: 'loading', message });
  }

  /** Create an `message` toast (type = `default`). */
  message(
    message: string | Type<unknown>,
    data?: ExternalToast
  ): number | string {
    return this.create({ ...data, message });
  }

  /**
   * Create a custom toast.
   * The `component` parameter must be specified (content renderer).
   */
  custom<T>(component: Type<T>, data?: ExternalToast): number | string {
    const id = data?.id ?? ++_nextUniqueId;
    this.create({ component, id, ...data });
    return id;
  }

  /** When dismissed, remove a toast's height from collection by toast id. */
  removeHeight(id: number | string): void {
    const entryExists = this._heights().some(h => h.toastId === id);
    if (entryExists) {
      this._heights.update(prev => prev.filter(height => height.toastId !== id));
    }
  }

  /** When created, add a toast's height to collection by toast id. */
  addHeight(height: HeightT): void {
    this._heights.update(prev => [height, ...prev].sort(this._heightsCompareFn));
  }

  /** Reset `_toasts` & `_heights` collections to `[]`. */
  reset(): void {
    isArrayWithLength(this._toasts()) && this._toasts.set([]);
    isArrayWithLength(this._heights()) && this._heights.set([]);
  }
}